import Side from "../partials/Side";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { url } from "../../url.config";
import { Bars, MutatingDots } from 'react-loader-spinner'
import { toast } from "react-toastify";
function Charges() {
    const [loaded, setLoaded] = useState(false)
    const token = localStorage.getItem('token')
    const [charge,setCharge]=useState({})
    const [charges,setCharges]=useState(null)
    const [status,setStatus]=useState(null)
    const [chargeId,setChargeId]=useState("")
    const [tax,setTax]=useState(null)
    useEffect(() => {
        fetch(`${url}/get-charges`, {
            headers: { Authorization: `bearer ${token}`, "Content-Type": "application/json" }
        }).then((res) => { return res.json() })
            .then(response => {
                if (response.status === true) {
                    setCharge(response.data)
                    setLoaded(true)
                    console.log(response.data)
                  
                }
            })
    }, [
        
    ])

   async function handleStatus(e,supportReqId){
        fetch(`${url}/support/update/${supportReqId}`, {
            headers: { Authorization: `bearer ${token}`, "Content-Type": "application/json" }
        }).then((res) => { return res.json() })
            .then(async response => {
            //     if (response.status === true) {
            //       const filteredData=await  support.filter(i=>{
            //           return i._id!==response.data._id
            //         })
               
            //   await filteredData.push(response.data)
            // //    SetSupport(filteredData)
            //     }
            }) 
    }

    function handleSubmit(e){
        e.preventDefault()
   const formData={charges,tax,status}
    console.log(formData)
   fetch(`${url}/edit-charges/${chargeId}`, {
    method:"POST",
    headers: { Authorization: `bearer ${token}`, "Content-Type": "application/json" },
    body:JSON.stringify(formData)
}).then((res) => { return res.json() })
    .then(response => {
        if (response.status === true) {
            toast.success(response.message)
           window.location.reload()
        }else{
            console.log("hello")
            toast.error(response.message)
        }
    })
    }
    return ( 
        <>
        <div className="container-fluid" style={{background :"#EAF8EF",height:"100vh"}}>
        <div className="row">
            <Side/>
            <div className="col-md-2"> </div>
            <div className="col-md-10">
            <h3 className="ms-5 mt-3">SUPPORT QURIES</h3>
                        {loaded == false ?
                            <div className="loader" style={{ marginLeft: "46%", marginTop: "21%" }}>
                                <MutatingDots
                                    height="100"
                                    width="100"
                                    color="#02BBB7"
                                    secondaryColor='#02BBB7'
                                    radius='12.5'
                                    ariaLabel="mutating-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                />
                            </div>
                            :
                        <div className='table-banner'>
                            <table className='table table-borderless text-center mt-5 table-banner'>
                                <thead className='mb-2'>
                                    <tr className='' style={{ background: "#F6F6F6", height: "50px" }}>
                                        {/* <th>S No</th> */}
                                        <th>Charges</th>
                                        <th>Tax</th>
                                        <th>Status</th>

                                        <th>Edit</th>
                                    </tr>
                                </thead>
                                <tbody className=''>
                                    {/* {charge.map((i, key) => ( */}
                                        <>
                                            <tr className="mt-2" style={{ background: "#F0EBEB" }}>
                                                {/* <td>{key+1}</td> */}
                                                <td>{charge.charges}</td>
                                                <td>{charge.tax}</td>
                                               {charge.status==0 && <td>active</td>}
                                               {charge.status==1 && <td>in active</td>}
                                               
                                               <td><Link  ><i className="bi bi-pencil-fill text-success" data-bs-toggle="modal" data-bs-target="#staticBackdrop6"
                                                onClick={(e)=>{
                                                    setCharges(charge.charges)
                                                    setStatus(charge.status)
                                                    setChargeId(charge._id)
                                                    setTax(charge.tax)
                                                }}
                                                ></i></Link></td>
                                            </tr>
                                            <br />
                                        </>
                                 {/* ))} */}

                                </tbody>
                            </table>
                           
                        </div>
}
            </div>
        </div>
        </div>
        <div class="modal fade" id="staticBackdrop6" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" style={{ border: "none" }}>
                            <div class="modal-content" style={{ borderRadius: "15px", border: "none" }}>
                                <div class="modal-header">
                                    <h5 class="modal-title" id="staticBackdrop">Edit Charges</h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                  
                                <form onSubmit={(e)=>{handleSubmit(e)}}>
                <label>Charges*</label>
                <input type="number" className="form-input"
                 value={charges}
                 onChange={(e) => { setCharges(e.target.value); }}
                />
                
                <label>Tax*</label>
                <input type="text" className="form-input"
                 value={tax}
                 onChange={(e) => { setTax(e.target.value); }}
                />

                <label  className="mt-2">Status*</label>
                <br />
                <select className="form-input"  value={status} onChange={(e)=>{setStatus(e.target.value)}}>
                    <option selected>Select</option>
                    <option value={`${0}`} >active</option>
                    <option value={`${1}`} >Inactive</option>
                </select>
                
                
                
               

                <button type="submit" className="submit-btn">Submit</button>
            </form>  
                                </div>

                            </div>
                        </div>
                    </div>
        </>
     );
}

export default Charges;
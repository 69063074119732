import Side from "../partials/Side";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { url } from "../../url.config";
import { Bars, MutatingDots } from 'react-loader-spinner'
import { toast } from "react-toastify";
function Nursing() {
    const [loaded, setLoaded] = useState(false)
    const token = localStorage.getItem('token')
    const [nursing,setNursing]=useState([])

       
    useEffect(() => {
        fetch(`${url}/home-nursing/all/`, {
            headers: { Authorization: `bearer ${token}`, "Content-Type": "application/json" }
        }).then((res) => { return res.json() })
            .then(response => {
                if (response.status === true) {
                    setNursing(response.data)
                    setLoaded(true)
                    console.log(response.data)
                  
                }
            })
    }, [])
    return ( 
        <>
              <div className="container-fluid" style={{background :"#EAF8EF",height:"100vh"}}>
              <div className="row">
                <Side/>
                <div className="col-md-2"> </div>
                <div className="col-md-10">
                <h3 className="ms-5 mt-3">HOME NURSING</h3>
                        {loaded == false ?
                            <div className="loader" style={{ marginLeft: "46%", marginTop: "21%" }}>
                                <MutatingDots
                                    height="100"
                                    width="100"
                                    color="#02BBB7"
                                    secondaryColor='#02BBB7'
                                    radius='12.5'
                                    ariaLabel="mutating-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                />
                            </div>
                            :
                            <div className='table-banner'>
                            <table className='table table-borderless text-center mt-5 table-banner'>
                                <thead className='mb-2'>
                                    <tr className='' style={{ background: "#F6F6F6", height: "50px" }}>
                                        <th>S No</th>
                                        <th>fullName</th>
                                        <th>Phone</th>
                                        <th>city</th>
                                        <th>Status</th>
                                       
                                    </tr>
                                </thead>
                                <tbody className=''>
                                    {nursing.map((i, key) => (
                                        <>
                                            <tr className="mt-2" style={{ background: "#F0EBEB" }}>
                                               <td>{key+1}</td>
                                               <td>{i.fullName}</td>
                                               <td>{i.phone}</td>
                                               <td>{i.city}</td>
                                               <td>{i.status}</td>
                                              
                                            </tr>
                                            <br />
                                        </>
                                 ))}

                                </tbody>
                            </table>
                           
                        </div>
                        }
                </div>
              </div>
              </div>
        </>
     );
}

export default Nursing;
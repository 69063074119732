import Side from "../partials/Side";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { url } from "../../url.config";
import { Bars, MutatingDots } from 'react-loader-spinner'
import { ToastContainer, toast } from "react-toastify";
function ViewDoc() {
    const [loaded, setLoaded] = useState(false)
    const token = localStorage.getItem('token')
    const [physio,SetDoctor]=useState({})
    const [totalEarning,setTotalEarning]=useState(null)
    const {doctorId} =useParams()
    let history = useNavigate()
    useEffect(() => {
        fetch(`${url}/doctor/single/${doctorId}/?patientId=${doctorId}`, {
            headers: { Authorization: `bearer ${token}`, "Content-Type": "application/json" }
        }).then((res) => { return res.json() })
            .then(response => {
                if (response.status === true) {
                    SetDoctor(response.data)
                    setLoaded(true)
                    console.log(response.data)
                    setTotalEarning(response.totalEarning)
                  
                }
            })
    }, [
        
    ])

    function handleAccountStatus(){
        if(physio.accountStatus===0){
            if(physio.hospitalId){
            const result=  window.confirm("This Is Hospital Doctor Are You Sure To Verify Hospital")
            if(result===true){
                fetch(`${url}/aprrove-and-reject-doctors/${doctorId}/?status=1`, {
                    headers: { Authorization: `bearer ${token}`, "Content-Type": "application/json" }
                }).then((res) => { return res.json() })
                    .then(response => {
                        if (response.status === true) {
                            toast.success(response.message)
                            window.location.reload()
                        }
                    }) 
            }
            }else{
                const result=  window.confirm("Are You Sure To Verify This Doctor")
                if(result===true){
                    fetch(`${url}/aprrove-and-reject-doctors/${doctorId}/?status=1`, {
                        headers: { Authorization: `bearer ${token}`, "Content-Type": "application/json" }
                    }).then((res) => { return res.json() })
                        .then(response => {
                            if (response.status === true) {
                                toast.success(response.message)
                                window.location.reload()
                            }
                        }) 
                }
            }
        }else if(physio.accountStatus===1){
            if(physio.hospitalId){
                const result=   window.confirm("This Is Hospital Doctor Are You Sure To Ban Hospital")
                if(result===true){
                    fetch(`${url}/aprrove-and-reject-doctors/${doctorId}/?status=0`, {
                        headers: { Authorization: `bearer ${token}`, "Content-Type": "application/json" }
                    }).then((res) => { return res.json() })
                        .then(response => {
                            if (response.status === true) {
                                toast.success(response.message)
                                window.location.reload()
                            }
                        }) 
                }
              }else {
                const result=   window.confirm("Are You Sure To Ban This Doctor")
                if(result===true){
                    fetch(`${url}/aprrove-and-reject-doctors/${doctorId}/?status=0`, {
                        headers: { Authorization: `bearer ${token}`, "Content-Type": "application/json" }
                    }).then((res) => { return res.json() })
                        .then(response => {
                            if (response.status === true) {
                                toast.success(response.message)
                                window.location.reload()
                            }
                        }) 
                }
              }
        }
    }
    return ( 
        <>
          <div className="container-fluid" style={{background :"white",height:"100vh"}}>
          <div className="row">
            <Side/>
            <div className="col-md-2"> </div>
            <div className="col-md-10">
            <h3 className="ms-1 mt-3">
            <i class="bi bi-arrow-left-circle-fill me-2 mt-2  fs-2" onClick={() => { history(-1) }} style={{ cursor: "pointer", color: "#02BBB7" }}></i>
                DOCTORS DETAILS
                </h3>
            
            {loaded == false ?
                            <div className="loader" style={{ marginLeft: "46%", marginTop: "21%" }}>
                                <MutatingDots
                                    height="100"
                                    width="100"
                                    color="#02BBB7"
                                    secondaryColor='#02BBB7'
                                    radius='12.5'
                                    ariaLabel="mutating-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                />
                            </div>
                            :
                            <>
<ToastContainer/>
                                <div className="row">
                                    {/* <i class="bi bi-arrow-left-circle-fill  fs-1" onClick={() => { history(-1) }} style={{ cursor: "pointer", color: "green" }}></i> */}
                                    <div className="physio-details col-md-3">
                                        <img src={physio.profileImage} />
                                    </div>
                                    <div className="physio-details col-md-5">
                                        <div className="physio-details-1">
                                            <h4>{physio.fullName}</h4>
                                            <Link>{physio.specialization}</Link>
                                            <p className="mt-3 text-secondary">ratings</p>
                                            <h6>{physio.starRating}</h6>
                                            <p>Total Reviews : {physio.totalReviews}</p>
                                            <h6 className="text-secondary mt-3">Joined On {`${new Date(physio.createdAt).getDate()}/${new Date(physio.createdAt).getMonth() + 1}/${new Date(physio.createdAt).getFullYear()}`}</h6>
                                            <p className="text-secondary">{physio.workExperience} Years of Experience</p>
                                        </div>
                                    </div>
                                    <div className="physio-details col-md-4">
                                        {physio.accountStatus === 1 ?
                                            <h5>Account <i class="bi bi-patch-check-fill text-success">verified</i></h5>
                                            :
                                            <h5>Account <i class="bi bi-slash-circle-fill text-danger">not Verified</i></h5>
                                        }

                                        <h5 className="text-secondary">Wallet Balance : {totalEarning}</h5>
                                        <h5 className="text-secondary">Total Appointments : {physio.patientCount}</h5>
                                        <h6 className="text-primary" style={{ cursor: "pointer" }} data-bs-toggle="modal" data-bs-target="#staticBackdrop5">View transactions</h6>
                                       
                                        {physio.accountStatus === 1 ?

                                            <h6 className="text-danger " onClick={handleAccountStatus} style={{cursor:"pointer"}}><i class="bi bi-slash-circle-fill text-danger">Ban Now</i></h6>
                                            :
                                            <h6 className="text-danger " onClick={handleAccountStatus} style={{cursor:"pointer"}}><i class="bi bi-patch-check-fill text-success">Verify Now</i></h6>
                                        }
                                    </div>

                                </div>
                                <hr />
                                <div className="row">
                                    <h5>Personal Info</h5>
                                    <div className="col-md-2">

                                        <ul class="list-group list-group-flush">
                                            <li class="list-group-item">Email</li>
                                            <li class="list-group-item">phone</li>
                                            {/* <li class="list-group-item">Aadhar Number</li> */}
                                            {/* <li class="list-group-item">Bachelor Degree</li> */}
                                            
                                        </ul>
                                    </div>
                                    <div className="col-md-1"></div>
                                    <div className="col-md-4">

                                        <ul class="list-group list-group-flush">
                                            <li class="list-group-item">{physio.email}</li>
                                            <li class="list-group-item">{physio.phone}</li>
                                            {/* <li class="list-group-item">{physio.aadharNumber}</li> */}
                                            {/* <li class="list-group-item"><a target="_blank" href={physio.bachelorDegree}>View</a></li>
                                            {physio.iapMember == 1 && <li class="list-group-item"><a target="_blank" href={physio.iapDoc}>View</a></li>}
                                            {physio.iapMember == 1 && <li class="list-group-item"><a target="_blank" href={physio.iapDoc}>View</a></li>} */}
                                        </ul>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <h5>Clinic Info</h5>
                                    <div className="col-md-2">

                                        <ul class="list-group list-group-flush">
                                            <li class="list-group-item">Clinic Name</li>
                                            <li class="list-group-item">consultation mode</li>
                                            <li class="list-group-item">Images</li>
                                            <li class="list-group-item">Address</li>

                                        </ul>
                                    </div>
                                    {/* <div className="col-md-1"></div> */}
                                    <div className="col-md-5">

                                        <ul class="list-group list-group-flush">
                                            <li class="list-group-item">{physio.clinic.clinicName}</li>
                                            <li class="list-group-item">
                                                {physio.clinic.workingDays.map(i => (
                                                    <span> {i} /</span>
                                                ))}
                                            </li>
                                            {/* <li class="list-group-item">{physio.clinic.consultationMode[0]}({physio.clinic.homeVisitCharges}) / {physio.clinic.consultationMode[1]}({physio.clinic.clinicVisitCharges} ) / {physio.clinic.consultationMode[3]}({physio.clinic.teleConsultationCharges})</li> */}
                                            <li class="list-group-item text-primary" style={{ cursor: "pointer" }} data-bs-toggle="modal" data-bs-target="#staticBackdrop1">View</li>
                                            <li class="list-group-item">{physio.clinic.landmark},{physio.clinic.city},{physio.clinic.state},{physio.clinic.zipCode}</li>

                                        </ul>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <h5>Bank Details</h5>
                                    <div className="col-md-2">

                                        <ul class="list-group list-group-flush">
                                            <li class="list-group-item">Bank Name</li>
                                            <li class="list-group-item">Account Number</li>
                                            <li class="list-group-item">IFSC code</li>
                                            <li class="list-group-item">Account Holder</li>

                                        </ul>
                                    </div>
                                    <div className="col-md-1"></div>
                                    <div className="col-md-4">

                                        <ul class="list-group list-group-flush">
                                            <li class="list-group-item">{physio.bankDetails.bankName}</li>
                                            <li class="list-group-item">{physio.bankDetails.accountNumber}</li>
                                            <li class="list-group-item">{physio.bankDetails.ifscCode}</li>
                                            <li class="list-group-item">{physio.bankDetails.accountHolderName}</li>

                                        </ul>
                                    </div>
                                </div>
                            </>
                        }
            </div>
          </div>
          </div>
          <div class="modal fade" id="staticBackdrop1" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel1" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel1">Clinic Images</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
                                <div class="carousel-inner">
                                    <div class="carousel-item active">

                                        <img src={physio.clinic?.clinicPhotos[0]} class="d-block w-100" style={{ width: "200px", height: "400px" }} alt="" />


                                    </div>
                                    {physio.clinic?.clinicPhotos?.map(i => (
                                        <div class="carousel-item ">

                                            <img src={i} class="d-block w-100" style={{ width: "200px", height: "400px" }} alt="" />


                                        </div>
                                    ))}
                                </div>
                                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Previous</span>
                                </button>
                                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            {/* <div class="modal fade" id="staticBackdrop5" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel1" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel1">Transactions</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <table className="table table-hover">
                                <tr>
                                    <th>s.no</th>
                                    <th>Type</th>
                                    <th>From</th>
                                    <th>Date</th>
                                    <th>Amount</th>
                                </tr>
                                {transaction.map((i,key) => (
                                    <>
                                    <tr>
                                        <td>{key+1}</td>
                                        {i.transactionType==1 && <td>Consultation</td>}
                                        {i.transactionType==0 && <td>Commision</td>}
                                        <td>{i.patientDetails.fullName}</td>
                                        <td>{new Date(i.createdAt).getDate()}/{new Date(i.createdAt).getMonth()+1}/{new Date(i.createdAt).getFullYear()}</td>
                                        <td>{i.amount}</td>
                                        {i.type==0 && <td className="btn btn-success mt-2 mb-2">+</td>}
                                        {i.type==1 && <td className="btn btn-danger mt-2 mb-2">-</td>}
                                    </tr>
                                    </>
                                ))}
                            </table>
                        </div>

                    </div>
                </div>
            </div> */}
        </>
     );
}

export default ViewDoc;
import { useContext, useEffect } from "react";
import { useState } from "react"
import { Link, NavLink } from "react-router-dom"
import { LoginContext } from "../../LoginContext";
function Side() {
    const [admin, setAdmin] = useState({})
    const token = localStorage.getItem('token')
    const verifyCount = localStorage.getItem('verifyCount')
    const wCount = localStorage.getItem('wCount')
    const sCount = localStorage.getItem('sCount')
    const adminName = localStorage.getItem('adminName')
    // useEffect(()=>{
    //     useEffect(()=>{
    //         fetch(`http://24.199.104.72/api/reports`,{
    //             headers: {Authorization: `bearer ${token}`,"Content-Type":"application/json" }
    //         }).then((res)=>{return res.json()})
    //         .then(response=>{
    //             if(response.status===true){
    //                 setAdmin(response.reports)
    //                 // setLoaded(true)
    //                //console.log(response.reports)
    //             }
    //         })
    //      },[])
    // },[])
    // const{loginstatus,setLoginstatus,expirationTime,setExpTime,verifyCount,setVerifyCount} =useContext( LoginContext)

    function handlePass(e){
      let pass=  document.getElementById('pass').value
     let passIcon=   document.getElementById('passIcon').attributes[0].value
    if( pass=="bi bi-eye-fill"){
       
    }
    }
    return (


        <>

            {/* <div className="col-md-2" style={{background :"#9DE7AC" ,height :"100vh",position:"static"}}>
                <h3 className="text-center text-success">RENTAH</h3>
                <p className="ms-3 mt-5 fs-5">MENU</p>
            <NavLink to='/' className="btn side-btn form-control mt-3">DASHBOARD</NavLink>
            <br/>
            <NavLink to='/users' className="btn side-btn form-control mt-3">USERS</NavLink>
            <br/>
            <NavLink to='/reports' className="btn side-btn form-control mt-3 ">REPORTS</NavLink>
            <br/>
            <NavLink to='/banner' className="btn side-btn form-control mt-3 ">BANNERS</NavLink>
            <br/>
            <NavLink to='/abc' className="btn side-btn  form-control mt-3">DASHBOARD</NavLink>
            <br/>
            <NavLink to='/pqr' className="btn side-btn form-control mt-3">DASHBOARD</NavLink>
            
            </div> */}
            <div className="col-md-2  sidebar " >
                <h3 to='*' className=" fs-3 text-center mt-2" style={{ color: "#02BBB7" }}>Medocly</h3>
                {/* <hr style={{color:"green"}}/> */}
                <div className="">

                <NavLink to="/">DASHBOARD</NavLink>
                    <NavLink to="/doctor">DOCTORS</NavLink>
                    <NavLink to="/hospital">HOSPITALS</NavLink>
                    <NavLink to="/banner">BANNERS</NavLink>
                    <NavLink to="/category">CATEGORIES</NavLink>
                    <NavLink to="/department">DEPARTMENT</NavLink>
                    <NavLink to="/degree">DEGREE</NavLink>
                    <NavLink to="/appointment">APPOINTMENTS</NavLink>
                    <NavLink to="/support">SUPPORT</NavLink>
                    <NavLink to="/withdrawl">WITHDRAWL REQ.</NavLink>
                    <NavLink to="/notification">NOTIFICATIONS</NavLink>
                    <NavLink to="/nursing">NURSING CARE REQ</NavLink>
                    <NavLink to="/verify">VERIFICATION REQUESTS<span style={{background:"green",color:"white",borderRadius:"50%", padding:"2px",position:"relative",bottom:"45px",right:"94px"}}>{verifyCount}</span></NavLink>
                    <NavLink to="/nursing-data">NURSING DATA</NavLink>
                    <NavLink to="/patient">PATIENTS</NavLink>
                    <NavLink to="/charges">CHARGES</NavLink>
                    {/* <div style={{ marginTop: "42vh", cursor: "pointer" }} data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                        <div className="" style={{ float: "left" }}>
                            <img src="../../profile.webp" style={{ width: "50px", height: "50px", borderRadius: "100%" }} />
                        </div>
                        <div className="ms-3" style={{ float: "left" }}>
                            <p className="mt-3 text-primary">View profile</p>

                        </div>
                    </div> */}
                </div>

            </div>
            <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">ROHIT ADMIN</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div className="row">
                                <div className="col-md-5">
                                    <img src="../../profile.webp" style={{ width: "200px", height: "200px", borderRadius: "50%", border: "2px solid green" }} />
                                </div>
                                <div className="col-md-7 mt-4">
                                    <ul class="list-group list-group-flush">
                                        <li class="list-group-item">Name : ROHIT SHARMA</li>
                                        <li class="list-group-item">Email : admin@gmail.com</li>
                                        <li class="list-group-item text-center">Password  <input type="password" className=" text-center" value="1234" id="pass"  /><i class="bi bi-eye-fill fs-5" id="passIcon" style={{marginLeft:"-25px",cursor:"pointer"}} onClick={handlePass}></i></li>
                                        
                                    </ul>
                                </div>
                                
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary">Understood</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-2 drawer" style={{ background: "#02BBB7", position: "sticky", top: "0", width: "100%" }}>
                <Link class=" text-light  " type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                    <i class="bi bi-bar-chart-steps fs-3"></i>
                </Link>
                <span to='*' className=" fs-1 ms-3 mt-2" style={{ color: "white" }}>Medocly</span>

                <div class="offcanvas offcanvas-start" style={{ background: "transparent" }} tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                    {/* <div class="offcanvas-header" style={{background :"transparent"}}>
                            <h5 class="offcanvas-title text-light" id="offcanvasExampleLabel">Offcanvas</h5>
                            <button type="button" class="btn-close text-reset bg-light" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div> */}
                    <div class="offcanvas-body ">
                        <span className="fs-3 ms-4 fw-bold" style={{ color: "#02BBB7" }}>Medocly</span>
                        <button type="button" class="btn-close text-reset  float-end mt-2 me-2 text-success" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        <br />

                        <NavLink to="/">DASHBOARD</NavLink>
                    <NavLink to="/doctor">DOCTORS</NavLink>
                    <NavLink to="/hospital">HOSPITALS</NavLink>
                    <NavLink to="/banner">BANNERS</NavLink>
                    <NavLink to="/category">CATEGORIES</NavLink>
                    <NavLink to="/department">DEPARTMENT</NavLink>
                    <NavLink to="/degree">DEGREE</NavLink>
                    <NavLink to="/appointment">APPOINTMENTS</NavLink>
                    <NavLink to="/support">SUPPORT</NavLink>
                    <NavLink to="/withdrawl">WITHDRAWL REQ.</NavLink>
                    <NavLink to="/notification">NOTIFICATIONS</NavLink>
                    <NavLink to="/nursing">NURSING CARE REQ</NavLink>
                    <NavLink to="/verify">VERIFICATION REQUESTS</NavLink>
                    <NavLink to="/nursing-data">NURSING DATA</NavLink>
                    <NavLink to="/patient">PATIENTS</NavLink>
                    <NavLink to="/charges">CHARGES</NavLink>
                        {/* <NavLink to="/booking-service">BOOKING SERVICES</NavLink>
                <NavLink to="/booking">BOOKINGS</NavLink> */}
                        {/* <NavLink to="/settings">APP SETTING</NavLink> */}

                    </div>
                </div>
            </div>
        </>
    );
}

export default Side;
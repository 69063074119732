import Side from "../partials/Side";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { url } from "../../url.config";
import { Bars, MutatingDots } from 'react-loader-spinner'
import { toast } from "react-toastify";
function Support() {
    const [loaded, setLoaded] = useState(false)
    const token = localStorage.getItem('token')
    const [support,SetSupport]=useState([])

    useEffect(() => {
        fetch(`${url}/support/get`, {
            headers: { Authorization: `bearer ${token}`, "Content-Type": "application/json" }
        }).then((res) => { return res.json() })
            .then(response => {
                if (response.status === true) {
                    SetSupport(response.data)
                    setLoaded(true)
                    console.log(response.data)
                  
                }
            })
    }, [
        
    ])

   async function handleStatus(e,supportReqId){
        fetch(`${url}/support/update/${supportReqId}`, {
            headers: { Authorization: `bearer ${token}`, "Content-Type": "application/json" }
        }).then((res) => { return res.json() })
            .then(async response => {
                if (response.status === true) {
                  const filteredData=await  support.filter(i=>{
                      return i._id!==response.data._id
                    })
               
              await filteredData.push(response.data)
               SetSupport(filteredData)
                }
            }) 
    }
    return ( 
        <>
        <div className="container-fluid" style={{background :"#EAF8EF",height:"100vh"}}>
        <div className="row">
            <Side/>
            <div className="col-md-2"> </div>
            <div className="col-md-10">
            <h3 className="ms-5 mt-3">SUPPORT QURIES</h3>
                        {loaded == false ?
                            <div className="loader" style={{ marginLeft: "46%", marginTop: "21%" }}>
                                <MutatingDots
                                    height="100"
                                    width="100"
                                    color="#02BBB7"
                                    secondaryColor='#02BBB7'
                                    radius='12.5'
                                    ariaLabel="mutating-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                />
                            </div>
                            :
                        <div className='table-banner'>
                            <table className='table table-borderless text-center mt-5 table-banner'>
                                <thead className='mb-2'>
                                    <tr className='' style={{ background: "#F6F6F6", height: "50px" }}>
                                        <th>S No</th>
                                        <th>Email</th>
                                        <th>Description</th>
                                        <th>Status</th>
                                        <th>Posted On</th>
                                        <th>Mark As Solved</th>
                                    </tr>
                                </thead>
                                <tbody className=''>
                                    {support.map((i, key) => (
                                        <>
                                            <tr className="mt-2" style={{ background: "#F0EBEB" }}>
                                                <td>{key+1}</td>
                                                <td>{i.email}</td>
                                               <td>{i.description}</td>
                                               {i.status==0 && <td>Pending</td>}
                                               {i.status==1 && <td>Completed</td>}
                                               <td>{new Date(i.createdAt).getDate()}/{new Date(i.createdAt).getMonth()+1}/{new Date(i.createdAt).getFullYear()}</td>
                                               {i.status==0 && <td><i style={{cursor:"pointer"}} onClick={(e)=>{handleStatus(e,i._id)}} class="bi bi-bookmark"></i></td>}
                                               {i.status==1 && <td><i class="bi bi-bookmark-check-fill"></i></td>}
                                            </tr>
                                            <br />
                                        </>
                                 ))}

                                </tbody>
                            </table>
                           
                        </div>
}
            </div>
        </div>
        </div>
        </>
     );
}

export default Support;
import Side from "../partials/Side";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { url } from "../../url.config";
import { Bars, MutatingDots } from 'react-loader-spinner'
import { toast } from "react-toastify";
function Degree() {
    const [loaded, setLoaded] = useState(false)
    const token = localStorage.getItem('token')
    const [degree,setDegree]=useState([])
    const [dep,setDep]=useState([])
    const [charges,setCharges]=useState(null)
    const [singleDegree,setSingleDegree]=useState("")
    const [degreeId,setDegreeId]=useState("")
    const [tax,setTax]=useState(null)
    
    useEffect(() => {
        fetch(`${url}/data/get`, {
            headers: { Authorization: `bearer ${token}`, "Content-Type": "application/json" }
        }).then((res) => { return res.json() })
            .then(response => {
                if (response.status === true) {
                    setDegree(response.data.degree)
                    setDep(response.data.dep)
                    setLoaded(true)
                    setDegreeId(response.data._id)
                    console.log(response.data)
                  
                }
            })
    }, [
        
    ])

   async function handleStatus(e,supportReqId){
        fetch(`${url}/support/update/${supportReqId}`, {
            headers: { Authorization: `bearer ${token}`, "Content-Type": "application/json" }
        }).then((res) => { return res.json() })
            .then(async response => {
            //     if (response.status === true) {
            //       const filteredData=await  support.filter(i=>{
            //           return i._id!==response.data._id
            //         })
               
            //   await filteredData.push(response.data)
            // //    SetSupport(filteredData)
            //     }
            }) 
    }

    function handleSubmit(e){
        e.preventDefault()
        degree.push(singleDegree)
        setDegree(degree)
   const formData={degree,dep}

    console.log(formData)
   fetch(`${url}/data/edit/${degreeId}`, {
    method:"POST",
    headers: { Authorization: `bearer ${token}`, "Content-Type": "application/json" },
    body:JSON.stringify(formData)
}).then((res) => { return res.json() })
    .then(response => {
        if (response.status === true) {
            toast.success(response.message)
           window.location.reload()
        }else{
            console.log("hello")
            toast.error(response.message)
        }
    })
    }

   async function handleDelete(deg){
    // let degree=[]
     const result =window.confirm("Are You Sure To Delete Degree")
     if(result===true){
     const filData= degree.filter(j=>{
        return j!==deg
      })
     await setDegree(pre=>pre=filData)
    //  degree=degree
      const formData={degree,dep}

      console.log(formData)
     fetch(`${url}/data/edit/${degreeId}`, {
      method:"POST",
      headers: { Authorization: `bearer ${token}`, "Content-Type": "application/json" },
      body:JSON.stringify(formData)
  }).then((res) => { return res.json() })
      .then(response => {
          if (response.status === true) {
              toast.success(response.message)
            //  window.location.reload()
          }else{
              console.log("hello")
              toast.error(response.message)
          }
      })
     }
    }
    return ( 
        <>
        <div className="container-fluid" style={{background :"#EAF8EF",height:"100vh"}}>
        <div className="row">
            <Side/>
            <div className="col-md-2"> </div>
            <div className="col-md-10">
            <h3 className="ms-5 mt-3">DEGREES</h3>
            <button data-bs-toggle="modal" data-bs-target="#staticBackdrop4" style={{border:"none",background:"green",color:"white",paddingLeft:"20px",paddingRight:"20px",paddingTop:"5px",paddingBottom:"5px",borderRadius:"5px"}}>Add + </button>
                        {loaded == false ?
                            <div className="loader" style={{ marginLeft: "46%", marginTop: "21%" }}>
                                <MutatingDots
                                    height="100"
                                    width="100"
                                    color="#02BBB7"
                                    secondaryColor='#02BBB7'
                                    radius='12.5'
                                    ariaLabel="mutating-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                />
                            </div>
                            :
                        <div className='table-banner'>
                            <table className='table table-borderless text-center mt-5 table-banner'>
                                <thead className='mb-2'>
                                    <tr className='' style={{ background: "#F6F6F6", height: "50px" }}>
                                        <th>S No</th>
                                        <th>Degree</th>
                                        {/* <th>Action</th> */}
                                        {/* <th>Tax</th> */}
                                        {/* <th>Status</th> */}

                                        {/* <th>Edit</th> */}
                                    </tr>
                                </thead>
                                <tbody className=''>
                                    {degree.map((i, key) => (
                                        <>
                                            <tr className="mt-2" style={{ background: "#F0EBEB" }}>
                                                <td>{key+1}</td>
                                                <td>{i}</td>
                                                {/* <td><Link onClick={(e)=>{handleDelete(i)}}><i class="bi bi-trash3-fill text-danger"></i></Link></td> */}
                                                {/* <td>{i.tax}</td>
                                               {i.status==0 && <td>active</td>}
                                               {i.status==1 && <td>in active</td>} */}
                                               
                                               {/* <td><Link  ><i className="bi bi-pencil-fill text-success" data-bs-toggle="modal" data-bs-target="#staticBackdrop6"
                                                onClick={(e)=>{
                                                    // setCharges(i.charges)
                                                    // setStatus(i.status)
                                                    // setChargeId(i._id)
                                                    // setTax(i.tax)
                                                }}
                                                ></i></Link></td> */}
                                            </tr>
                                            <br />
                                        </>
                                 ))}

                                </tbody>
                            </table>
                           
                        </div>
}
            </div>
        </div>
        </div>
        <div class="modal fade" id="staticBackdrop4" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" style={{ border: "none" }}>
                            <div class="modal-content" style={{ borderRadius: "15px", border: "none" }}>
                                <div class="modal-header">
                                    <h5 class="modal-title" id="staticBackdrop">Edit Charges</h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                  
                                <form onSubmit={(e)=>{handleSubmit(e)}}>
                <label>Degree*</label>
                <input type="text" className="form-input"
                 value={singleDegree}
                 onChange={(e) => { setSingleDegree(e.target.value.toUpperCase());
                  
                }}
                />
                
               
                
                
                
               

                <button type="submit" className="submit-btn">Submit</button>
            </form>  
                                </div>

                            </div>
                        </div>
                    </div>
        </>
     );
}

export default Degree;
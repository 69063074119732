import { useState } from "react"
import { url } from "../../url.config"
import { toast } from "react-toastify"

function AddBanner(props) {
    let bannerId =props.bannerId
    const [bannerName,SetBannerName]=useState("")
    const [bannerUrl,SetBannerUrl]=useState("")
    const[bannerImage,setBannerImage]=useState("")
    const [bannerType,setBannerType]=useState(null)
    const token = localStorage.getItem('token')

    function handleImage(e){
        const formData = new FormData();

        formData.append('image', e.target.files[0])
        console.log(e.target.file)
        // console.log(fileList)
        fetch(`${url}/upload-single-image`, {
            method: "POST",
            //   headers :{"Content-Type":"application/json" },
            body: formData
        }).then((res) => { return res.json() })
            .then(response => {
                setBannerImage(response.imageUrl)
                //  console.log(response.imageUrl)
            })
    }
  function  handleSubmit(e){
       e.preventDefault()
       const formData ={bannerName,bannerUrl,bannerImage,bannerType}
       console.log(formData)
       fetch(`${url}/banner/add`, {
        method:"POST",
        headers: { Authorization: `bearer ${token}`, "Content-Type": "application/json" },
        body:JSON.stringify(formData)
    }).then((res) => { return res.json() })
        .then(response => {
            if (response.status === true) {
                toast.success(response.message)
               window.location.reload()
            }
        })
    }
    return ( 
        <>
         <form onSubmit={(e)=>{handleSubmit(e)}}>
                <label>Banner Name*</label>
                <input type="text" className="form-input"
                 value={bannerName}
                 onChange={(e) => { SetBannerName(e.target.value); }}
                />
                <label className="mt-2">Banner Link*</label>
                <input type="text" className="form-input"
                 value={bannerUrl}
                 onChange={(e) => { SetBannerUrl(e.target.value); }}
                />
                <label  className="mt-2">Banner For*</label>
                <br />
                <select className="form-input" value={bannerType}  onChange={(e)=>{setBannerType(e.target.value)}}>
                    <option selected>Select</option>
                    <option value={`${0}`} >patient</option>
                    <option value={`${1}`} >doctor</option>
                </select>
                <label  className="mt-2">Banner Image*</label>
                <input type="file" className="form-input"
                // value={fullName}
                 onChange={handleImage}
                />
                {bannerImage==""?
                    <img src={bannerImage} style={{width:"150px", marginTop:"10px"}}/>
                    :
                    <img src={bannerImage} style={{width:"150px", marginTop:"10px"}}/>
                }
               

                <button type="submit" className="submit-btn">Submit</button>
            </form>
        </>
     );
}

export default AddBanner;
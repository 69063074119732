import Side from "../partials/Side";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { url } from "../../url.config";
import { Bars, MutatingDots } from 'react-loader-spinner'
import { toast } from "react-toastify";
function Notification() {
    const [loaded, setLoaded] = useState(false)
    const token = localStorage.getItem('token')
    const [notification,SetNotification]=useState([])
    
    return ( 
        <>
        <div className="container-fluid" style={{background :"#EAF8EF",height:"100vh"}}>
        <div className="row">
            <Side/>
            <div className="col-md-2"> </div>
            <div className="col-md-10">
            <h3 className="ms-5 mt-3">NOTIFICATIONS</h3>
            <div className="row">
                <div className="col-md-4 ms-5">
                <h5 className="text-secondary mt-3">SEND NOTIFICATIONS TO ALL PATIENTS</h5>
                  <form>
                    <label className="mt-1">Title</label>
                    <input type="text" className="form-control"/>
                    <label className="mt-3">Body</label>
                    <input type="text"  className="form-control"/>
                    <button type="submit" className="form-control mt-3" style={{background:"#02BBB7",borderRadius:"20px"}}>SEND</button>
                    </form>   
                    </div>
                    </div>   
                    <div className="row">
                <div className="col-md-4 ms-5">
                <h5 className="text-secondary mt-5">SEND NOTIFICATIONS TO ALL DOCS & HOSPITALS</h5>
                  <form>
                    
                    <label className="mt-1">Title</label>
                    <input type="text" className="form-control"/>
                   
                    <label className="mt-3">Body</label>
                    <input type="text"  className="form-control"/>
                    
                    <button type="submit" className="form-control mt-3" style={{background:"#02BBB7",borderRadius:"20px"}}>SEND</button>
                    </form>   
                    </div>
                    </div>   
            </div>
        </div>
        </div>
        </>
     );
}

export default Notification;
import Side from "../partials/Side";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { url } from "../../url.config";
import { Bars, MutatingDots, ThreeDots } from 'react-loader-spinner'
import { toast } from "react-toastify";
function Dashboard() {
    const [loaded, setLoaded] = useState(false)
    const token = localStorage.getItem('token')
    const [totalDoc,setTotalDoc]=useState(null)
    const [totalHos,setTotalHos]=useState(null)
    const [totalPat,setTotalPat]=useState(null)
    const [totalAppointments,setTotalAppointmnets]=useState(null)
    useEffect(() => {
        fetch(`${url}/kpis-data/admin`, {
            headers: { Authorization: `bearer ${token}`, "Content-Type": "application/json" }
        }).then((res) => { return res.json() })
            .then(response => {
                if (response.status === true) {
                    setTotalDoc(response.totalDoc)
                    setTotalHos(response.totalHos)
                    setTotalPat(response.totalPat)
                    setTotalAppointmnets(response.appointments)
                    setLoaded(true)
                    console.log(response.totalDoc)
                  
                }
            })
    }, [
        
    ])
    return ( 
        <>
        <div className="container-fluid" style={{background :"#EAF8EF",height:"100vh"}}>
        <div className="row">
            <Side/>
            <div className="col-md-2"> </div>
            <div className="col-md-10">
            <div className="row">
                            <div className="col-md-1"></div>
                            <div className="col-md-6">
                            <h3 className="mt-5">ANALYTICS OVERVIEW</h3>
                            </div>
                            <div className="col-md-2"></div>
                            {/* <div className="col-md-2 mt-5">
                                <select className="form-select">
                                    <option>Last 30 Days</option>
                                    <option>Last 7 Days</option>
                                    <option>Last 6 Months</option>
                                </select>
                            </div> */}
                        </div>
                    <div className="row mt-3">
                        <div className="col-md-1"></div>
                         <div className="col-md-10">
                            
                            <div className="card-dash mt-3 ">
                            <i className="bi bi-people text-center ms-5  fs-1"></i>
                            {loaded===false?
                             <div className="loader" style={{ marginLeft: "43%" }}>
                        <ThreeDots
                        visible={true}
                        height="50"
                        width="50"
                        color="#02BBB7"
                        radius="9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        />   
                        </div>
                        : 
                        <h3 className="text-center mt-3">{totalPat}</h3>
                        }
                           
                            <p className="text-center pb-3 fs-3">Total Patients</p>
                            </div>


                            <div className="card-dash ms-5">
                            <i className="bi bi-clipboard-plus text-center ms-5  fs-1"></i>
                            {loaded===false?
                             <div className="loader" style={{ marginLeft: "43%" }}>
                        <ThreeDots
                        visible={true}
                        height="50"
                        width="50"
                        color="#02BBB7"
                        radius="9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        />   
                        </div>
                        : 
                        <h3 className="text-center mt-3">{totalDoc}</h3>
                        }
                            <p className="text-center pb-3 fs-3">Total Doctors</p>
                            </div>


                            <div className="card-dash ms-5">
                            <i className="bi bi-clipboard-plus text-center ms-5  fs-1"></i>
                            {loaded===false?
                             <div className="loader" style={{ marginLeft: "43%" }}>
                        <ThreeDots
                        visible={true}
                        height="50"
                        width="50"
                        color="#02BBB7"
                        radius="9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        />   
                        </div>
                        : 
                        <h3 className="text-center mt-3">{totalHos}</h3>
                        }
                            <p className="text-center pb-3 fs-3">Total Hospitals</p>
                            </div>


                            
                            <div className="card-dash mt-5">
                            <i className="bi bi-clipboard-plus text-center ms-5  fs-1"></i>
                            {loaded===false?
                             <div className="loader" style={{ marginLeft: "43%" }}>
                        <ThreeDots
                        visible={true}
                        height="50"
                        width="50"
                        color="#02BBB7"
                        radius="9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        />   
                        </div>
                        : 
                        <h3 className="text-center mt-3">{totalAppointments}</h3>
                        }
                            <p className="text-center pb-3 fs-3">Total Appointment</p>
                            </div>
                         </div>
                         <div className="col-md-1"></div>
                    </div>
            </div>
        </div>
        </div>
        </>
     );
}

export default Dashboard;
import { useEffect, useState } from "react"
import { url } from "../../url.config"
import { toast } from "react-toastify"

function EditCategory(props) {
    const categoryId=props.categoryId
    const [name,setName]=useState("")
    const [icon,setIcon]=useState("")
    const token = localStorage.getItem('token')
    console.log(categoryId)
    useEffect(()=>{
        fetch(`${url}/category/single/${categoryId}`, {
            headers: { Authorization: `bearer ${token}`, "Content-Type": "application/json" }
        }).then((res) => { return res.json() })
            .then(response => {
                if (response.status == true) {
                    // SetBanner(response.data)
                    // setLoaded(true)
                   setName(response.data.name)
                   setIcon(response.data.icon)
                    console.log(response)
                  
                }
            })
    },[categoryId])

    function handleImage(e){
        const formData = new FormData();

        formData.append('image', e.target.files[0])
        console.log(e.target.file)
        // console.log(fileList)
        fetch(`${url}/upload-single-image`, {
            method: "POST",
            //   headers :{"Content-Type":"application/json" },
            body: formData
        }).then((res) => { return res.json() })
            .then(response => {
                setIcon(response.imageUrl)
                //  console.log(response.imageUrl)
            })
    }

    function  handleSubmit(e){
        e.preventDefault()
        const formData ={name,icon}
        console.log(formData)
        fetch(`${url}/category/edit/${categoryId}`, {
         method:"POST",
         headers: { Authorization: `bearer ${token}`, "Content-Type": "application/json" },
         body:JSON.stringify(formData)
     }).then((res) => { return res.json() })
         .then(response => {
             if (response.status === true) {
                 toast.success(response.message)
                window.location.reload()
             }
         })
     }
    return ( 
        <>
          <form method="post" onSubmit={(e)=>{handleSubmit(e)}}>
                <label>Category Name*</label>
                <input type="text" className="form-input"
                 value={name}
                 onChange={(e) => { setName(e.target.value); }}
                />
                {/* <label className="mt-2">Banner Link*</label>
                <input type="text" className="form-input"
                //  value={bannerUrl}
                //  onChange={(e) => { SetBannerUrl(e.target.value); }}
                /> */}
                {/* <label  className="mt-2">Banner For*</label>
                <br />
                <select className="form-input" >
                    <option selected>Select</option>
                    <option value={`${0}`} >patient</option>
                    <option value={`${1}`} >doctor</option>
                </select> */}
                <label  className="mt-2">Banner Image*</label>
                <input type="file" className="form-input"
                // value={icon}
                 onChange={handleImage}
                />
                {/* {bannerImage==""? */}
                    <img src={icon} style={{width:"150px", marginTop:"10px"}}/>
                   
               

                <button type="submit" className="submit-btn">Submit</button>
            </form>
        </>
     );
}

export default EditCategory;
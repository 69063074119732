import Side from "../partials/Side";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { url } from "../../url.config";
import { Bars, MutatingDots } from 'react-loader-spinner'
import { toast } from "react-toastify";
import AddNursing from "./AddNursing";
import EditNursing from "./EditNursing";
function NursingData() {
    const [loaded, setLoaded] = useState(false)
    const token = localStorage.getItem('token')
    const [nursing,setNursing]=useState([])
    // const [description,setDescription]=useState("")
    const [title,setTitle]=useState("")
    const [image,setImage]=useState("")
    const[description,setDescription]=useState("")
    const [status,setStatus]=useState(null)
    const [nursingDataId,setNursingDataId]=useState("")
    useEffect(() => {
        fetch(`${url}/home-nursing/`, {
            headers: { Authorization: `bearer ${token}`, "Content-Type": "application/json" }
        }).then((res) => { return res.json() })
            .then(response => {
                if (response.status === true) {
                    setNursing(response.data)
                    setLoaded(true)
                    console.log(response.data)
                  
                }
            })
    }, [])
    function handleDelete(e,nursingDataId){
        const result= window.confirm("Are You Sure To Delete Banner")
        if(result===true){
            fetch(`${url}/home-nursing/delete/${nursingDataId}`, {
                method:"DELETE",
                headers: { Authorization: `bearer ${token}`, "Content-Type": "application/json" }
            }).then((res) => { return res.json() })
                .then(response => {
                    if (response.status === true) {
                        toast.success(response.message)
                        window.location.reload()
                      
                    }
                }) 
        }
    }
    return ( 
        <>
         <div className="container-fluid" style={{background :"#EAF8EF",height:"100vh"}}>
              <div className="row">
                <Side/>
                <div className="col-md-2"> </div>
                <div className="col-md-10">
                <h3 className="ms-5 mt-3">HOME NURSING DATA</h3>
                <button data-bs-toggle="modal" data-bs-target="#staticBackdrop4" style={{border:"none",background:"green",color:"white",paddingLeft:"20px",paddingRight:"20px",paddingTop:"5px",paddingBottom:"5px",borderRadius:"5px"}}>Add + </button>
                        {loaded == false ?
                            <div className="loader" style={{ marginLeft: "46%", marginTop: "21%" }}>
                                <MutatingDots
                                    height="100"
                                    width="100"
                                    color="#02BBB7"
                                    secondaryColor='#02BBB7'
                                    radius='12.5'
                                    ariaLabel="mutating-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                />
                            </div>
                            :
                            <div className='table-banner'>
                            <table className='table table-borderless text-center mt-5 table-banner'>
                                <thead className='mb-2'>
                                    <tr className='' style={{ background: "#F6F6F6", height: "50px" }}>
                                        <th>S No</th>
                                        <th>Title</th>
                                        <th>Image</th>
                                        <th>Description</th>
                                        <th>Status</th>
                                        <th>Edit</th>
                                        <th>Delete</th>
                                    </tr>
                                </thead>
                                <tbody className=''>
                                    {nursing.map((i, key) => (
                                        <>
                                            <tr className="mt-2" style={{ background: "#F0EBEB" }}>
                                               <td>{key+1}</td>
                                               <td>{i.title}</td>
                                               <td><img src={i.image} style={{width:"120px"}}/></td>
                                               <td><Link onClick={(e)=>{setDescription(i.description)}} data-bs-toggle="modal" data-bs-target="#staticBackdrop6"><i class="bi bi-eye-fill"></i></Link></td>
                                             {i.status=="1" &&  <td>Active</td>} 
                                             {i.status=="0" &&  <td>In-Active</td>} 
                                               <td><Link  ><i className="bi bi-pencil-fill text-success" data-bs-toggle="modal" data-bs-target="#staticBackdrop7"
                                                onClick={(e)=>{
                                                   setTitle(i.title);
                                                   setImage(i.image);
                                                   setDescription(i.description);
                                                   setNursingDataId(i._id)
                                                   setStatus(i.status)
                                                }}
                                                ></i></Link></td>
                                                <td><Link onClick={(e)=>{handleDelete(e,i._id)}}><i class="bi bi-trash3-fill text-danger"></i></Link></td>
                                            </tr>
                                            <br />
                                        </>
                                 ))}

                                </tbody>
                            </table>
                           
                        </div>
                        }
                </div>
              </div>
              </div>
              <div class="modal fade" id="staticBackdrop6" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" style={{ border: "none" }}>
                            <div class="modal-content" style={{ borderRadius: "15px", border: "none" }}>
                                <div class="modal-header">
                                    <h5 class="modal-title" id="staticBackdrop">Description</h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">

                                    <p>{description}</p>

                                </div>

                            </div>
                        </div>
                    </div>


                    <div class="modal fade" id="staticBackdrop7" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" style={{ border: "none" }}>
                            <div class="modal-content" style={{ borderRadius: "15px", border: "none" }}>
                                <div class="modal-header">
                                    <h5 class="modal-title" id="staticBackdrop">Description</h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">

                                 <EditNursing nursingDataId={nursingDataId} title={title} image={image} description={description} status={status}/>

                                </div>

                            </div>
                        </div>
                    </div>


                    <div class="modal fade" id="staticBackdrop4" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" style={{ border: "none" }}>
                            <div class="modal-content" style={{ borderRadius: "15px", border: "none" }}>
                                <div class="modal-header">
                                    <h5 class="modal-title" id="staticBackdrop">Add Nursing</h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                  <AddNursing/>
                                 

                                </div>

                            </div>
                        </div>
                    </div>
        </>
     );
}

export default NursingData;
import Side from "../partials/Side";
import { Link } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { url } from "../../url.config";
import { Bars, MutatingDots } from 'react-loader-spinner'
import { toast } from "react-toastify";
import { LoginContext } from "../../LoginContext";
function VerificationRequest() {
    const [loaded, setLoaded] = useState(false)
    const{loginstatus,setLoginstatus,expirationTime,setExpTime,verifyCount,setVerifyCount} =useContext( LoginContext)
    const token = localStorage.getItem('token')
    const [doctor,SetDoctor]=useState([])
    const [value,setValue]=useState("doc")
    
    useEffect(() => {
        fetch(`${url}/verification-request?value=${value}`, {
            headers: { Authorization: `bearer ${token}`, "Content-Type": "application/json" }
        }).then((res) => { return res.json() })
            .then(response => {
                if (response.status === true) {
                    SetDoctor(response.data)
                    setLoaded(true)
                    console.log(response.data)
                //    window.location.reload()
                }
            })
    }, [
     value   
    ])

    function handleVerify(e,status,doctorId){
        if(status===0){
          const theRes=  window.confirm("Are You Sure To Verify This Doctor")
          if(theRes===true){
            fetch(`${url}/aprrove-and-reject-doctors/${doctorId}/?status=1`, {
                headers: { Authorization: `bearer ${token}`, "Content-Type": "application/json" }
            }).then((res) => { return res.json() })             
                .then(response => {
                
                        toast.success(response.message)
                        window.location.reload()
                        console.log(response)
                 
                }) 
          }
        }
    }
    return ( 
        <>
        <div className="container-fluid" style={{background :"#EAF8EF",height:"100vh"}}>
        <div className="row">
            <Side/>
            <div className="col-md-2"> </div>
            <div className="col-md-10">
               
            <h3 className="ms-5 mt-3">VERIFICATION REQUESTS</h3>
            <select  style={{outline:"none",width:"200px",height:"40px",borderRadius:"13px"}} onChange={(e)=>{setValue(e.target.value)}}>
                    <option value="doc">Doctors</option>
                    <option value="hos">Hospitals</option>
                </select>
                        {loaded == false ?
                            <div className="loader" style={{ marginLeft: "46%", marginTop: "21%" }}>
                                <MutatingDots
                                    height="100"
                                    width="100"
                                    color="#02BBB7"
                                    secondaryColor='#02BBB7'
                                    radius='12.5'
                                    ariaLabel="mutating-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                />
                            </div>
                            :
                            
                        <div className='table-banner'>
                            <table className='table table-borderless text-center mt-5 table-banner'>
                                {value==="doc"?
<>
                                <thead className='mb-2'>
                                    <tr className='' style={{ background: "#F6F6F6", height: "50px" }}>
                                        <th>S No</th>
                                        <th>Profile Photo</th>
                                        <th>Full Name</th>
                                        <th>Phone</th>
                                        <th>Email</th>
                                        <th>Ratings</th>
                                        <th>View</th>
                                        <th>Verify</th>
                                        <th>Delete</th>
                                    </tr>
                                </thead>
                                <tbody className=''>
                                    {doctor.map((i, key) => (
                                        <>
                                            <tr className="mt-2" style={{ background: "#F0EBEB" }}>
                                                <td>{key+1}</td>
                                                <td>
                                                    
                                                    <img style={{ width: "80px", height:"80px",borderRadius:"50%" }} src={i.profileImage} />
                                                </td>
                                                <td>{i.fullName}</td>
                                               <td>{i.phone}</td>
                                               <td>{i.email}</td>
                                               <td>{i.rating}</td>
                                               <td><Link to={`/doctor/details/${i._id}`} className="text-dark"><i class="bi bi-info-circle-fill"></i></Link></td>
                                               {i.accountStatus===0 && <td><Link  onClick={(e)=>{handleVerify(e,i.accountStatus,i._id)}}><i class="bi bi-patch-check-fill text-danger">verify</i></Link></td>}
                                               {i.accountStatus===1 && <td><Link  ><i class="bi bi-patch-check-fill text-success">Ban Now</i></Link></td>}
                                                <td><Link ><i class="bi bi-trash3-fill text-danger"></i></Link></td>
                                            </tr>
                                            <br />
                                        </>
                                 ))}

                                </tbody>
                                </>
                                :
                                <>
                                <thead className='mb-2'>
                                    <tr className='' style={{ background: "#F6F6F6", height: "50px" }}>
                                        <th>S No</th>
                                        <th>Profile Photo</th>
                                        <th>Full Name</th>
                                        <th>Phone</th>
                                        <th>Email</th>
                                        <th>Ratings</th>
                                        <th>View</th>
                                        <th>Verify</th>
                                        <th>Delete</th>
                                    </tr>
                                </thead>
                                <tbody className=''>
                                    {doctor.map((i, key) => (
                                        <>
                                            <tr className="mt-2" style={{ background: "#F0EBEB" }}>
                                                <td>{key+1}</td>
                                                <td>
                                                    
                                                    <img style={{ width: "80px", height:"80px",borderRadius:"50%" }} src={i?.photos?.[0]} />
                                                </td>
                                                <td>{i.hospitalName}</td>
                                               <td>{i.phone}</td>
                                               <td>{i.email}</td>
                                               <td>{i.rating}</td>
                                               <td><Link to={`/hospital/details/${i._id}`} className="text-dark"><i class="bi bi-info-circle-fill"></i></Link></td>
                                               {i.accountStatus===0 && <td><Link  onClick={(e)=>{handleVerify(e,i.accountStatus,i._id)}}><i class="bi bi-patch-check-fill text-danger">verify</i></Link></td>}
                                               {i.accountStatus===1 && <td><Link  ><i class="bi bi-patch-check-fill text-success">Ban Now</i></Link></td>}
                                                <td><Link ><i class="bi bi-trash3-fill text-danger"></i></Link></td>
                                            </tr>
                                            <br />
                                        </>
                                 ))}

                                </tbody>
                                </>
                                    }
                            </table>
                           
                        </div>
}
            </div>
        </div>
        </div>
        </>
     );
}

export default VerificationRequest;
function Pending() {
    return ( 
        <>
        <div className="conatiner-fluid">
            <div className="row">
                <div className="col-md-12">
                    <img src="https://t3.ftcdn.net/jpg/01/82/45/90/360_F_182459037_Z5oMYBIAJRKrGG8D6xkxtXErXJ0HT8Vs.jpg"/>
                </div>
            </div>

        </div>
        </>
     );
}

export default Pending;
import { useState } from "react"
import { url } from "../../url.config"
import { toast } from "react-toastify"
function AddNursing() {
    // let bannerId =props.bannerId
    const [title,setTitle]=useState("")
    const [image,setImage]=useState("")
    const[description,setDescription]=useState("")
    
    const token = localStorage.getItem('token')

    function handleImage(e){
        const formData = new FormData();

        formData.append('image', e.target.files[0])
        console.log(e.target.file)
        // console.log(fileList)
        fetch(`${url}/upload-single-image`, {
            method: "POST",
            //   headers :{"Content-Type":"application/json" },
            body: formData
        }).then((res) => { return res.json() })
            .then(response => {
                setImage(response.imageUrl) 
                //  console.log(response.imageUrl)
            })
    }
    function  handleSubmit(e){
        e.preventDefault()
        const formData ={title,image,description}
        console.log(formData)
        fetch(`${url}/home-nursing/add`, {
         method:"POST",
         headers: { Authorization: `bearer ${token}`, "Content-Type": "application/json" },
         body:JSON.stringify(formData)
     }).then((res) => { return res.json() })
         .then(response => {
             if (response.status === true) {
                 toast.success(response.message)
                window.location.reload()
             }
         })
     }
    return ( 
        <>
        <form onSubmit={(e)=>{handleSubmit(e)}}>
                <label>Title*</label>
                <input type="text" className="form-input"
                 value={title}
                 onChange={(e) => { setTitle(e.target.value); }}
                />
                <label className="mt-2">Description*</label>
                <input type="text" className="form-input"
                 value={description}
                 onChange={(e) => { setDescription(e.target.value); }}
                />
                
                <label  className="mt-2">Image*</label>
                <input type="file" className="form-input"
                // value={fullName}
                 onChange={handleImage}
                />
               
                    <img src={image} style={{width:"150px", marginTop:"10px"}}/>
              
               

                <button type="submit" className="submit-btn">Submit</button>
            </form>
        </>
     );
}

export default AddNursing;
import { useEffect, useState } from "react"
import urlConfig, { url } from "../../url.config"
import { toast } from "react-toastify"

function EditBanner(props) {
    let name =props.bannerName
    let urll =props.bannerUrl
    let image =props.bannerImage
    let type =props.bannerType
    let bannerId =props.bannerId
    const [bannerName,SetBannerName]=useState("")
    const [bannerUrl,SetBannerUrl]=useState("")
    const[bannerImage,setBannerImage]=useState("")
    const [bannerType,setBannerType]=useState(null)
    const token = localStorage.getItem('token')

    // const [bannerId,setBannerId]=useState(props.bannerId)

useEffect(()=>{
    fetch(`${url}/banner/single/${bannerId}`, {
        headers: { Authorization: `bearer ${token}`, "Content-Type": "application/json" }
    }).then((res) => { return res.json() })
        .then(response => {
            if (response.status === true) {
                // SetBanner(response.data)
                // setLoaded(true)
                SetBannerName(response.data.bannerName)
                SetBannerUrl(response.data.bannerUrl)
                setBannerImage(response.data.bannerImage)
                setBannerType(response.data.bannerType)
                console.log(response.data)
              
            }
        })
},[bannerId])

    function handleSubmit(e){
        e.preventDefault()
   const formData={bannerName,bannerUrl,bannerImage,bannerType}
//    console.log(formData)
   fetch(`${url}/banner/edit/${bannerId}`, {
    method:"POST",
    headers: { Authorization: `bearer ${token}`, "Content-Type": "application/json" },
    body:JSON.stringify(formData)
}).then((res) => { return res.json() })
    .then(response => {
        if (response.status === true) {
            toast.success(response.message)
           window.location.reload()
        }
    })
    }

    function handleImage(e){
        const formData = new FormData();

        formData.append('image', e.target.files[0])
        console.log(e.target.file)
        // console.log(fileList)
        fetch(`${url}/upload-single-image`, {
            method: "POST",
            //   headers :{"Content-Type":"application/json" },
            body: formData
        }).then((res) => { return res.json() })
            .then(response => {
                setBannerImage(response.imageUrl)
                //  console.log(response.imageUrl)
            })
    }
    return (
        <>
            <form onSubmit={(e)=>{handleSubmit(e)}}>
                <label>Banner Name*</label>
                <input type="text" className="form-input"
                 value={bannerName}
                 onChange={(e) => { SetBannerName(e.target.value); }}
                />
                <label className="mt-2">Banner Link*</label>
                <input type="text" className="form-input"
                 value={bannerUrl}
                 onChange={(e) => { SetBannerUrl(e.target.value); }}
                />
                <label  className="mt-2">Banner For*</label>
                <br />
                <select className="form-input" value={bannerType}  onChange={(e)=>{setBannerType(e.target.value)}}>
                    <option selected>Select</option>
                    <option value={`${0}`} >patient</option>
                    <option value={`${1}`} >doctor</option>
                </select>
                <label  className="mt-2">Banner Image*</label>
                <input type="file" className="form-input"
                // value={fullName}
                 onChange={handleImage}
                />
                {bannerImage==""?
                    <img src={image} style={{width:"150px", marginTop:"10px"}}/>
                    :
                    <img src={bannerImage} style={{width:"150px", marginTop:"10px"}}/>
                }
               

                <button type="submit" className="submit-btn">Submit</button>
            </form>
        </>
    );
}

export default EditBanner;
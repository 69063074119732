import { useEffect, useState } from 'react'
import { BrowserRouter as Router ,Route , Routes } from 'react-router-dom'
//  import Login from './component/auth/Login';
import Protected from './Protected'
import { LoginContext } from './LoginContext';
import Login from './component/auth/Login';
import Dashboard from './component/Dashboard/Dashboard';
import Category from './component/Category/Category';
import Doctor from './component/Doctor/Doctor';
import Banner from './component/Banner/Banner';
import Hospital from './component/Hospital/Hospital';
import Support from './component/Support/Support';
import WithdrawlRequest from './component/WithdrawlRequest/WithdrawlRequest';
import Notification from './component/Notification/Notification';
import ViewDoc from './component/Doctor/ViewDoc';
import ViewHospital from './component/Hospital/ViewHospital';
import Appointment from './component/Appointment/Appointment';
import Nursing from './component/Nursing/Nursing';
import VerificationRequest from './component/VerificationRequest/VerificationRequest';
import NursingData from './component/Nursing/NursingData';
import Patient from './component/Patient/Patient';
import ViewPatient from './component/Patient/ViewPatient';
import Charges from './component/Charges/Charges';
import Degree from './component/Degree/Degree';
import { url } from './url.config';
import Department from './component/Department/Department';
import Pending from './component/Pending';

function App() {
  const [verifyCount,setVerifyCount]=useState(0)
  const token = localStorage.getItem('token')
  useEffect(() => {
    fetch(`${url}/get-verification-count`, {
        headers: { Authorization: `bearer ${token}`, "Content-Type": "application/json" }
    }).then((res) => { return res.json() })
        .then(response => {
            if (response.status === true) {
                
              setVerifyCount(response.count)
              localStorage.setItem("verifyCount",response.vcount)
              localStorage.setItem("wCount",response.wReq)
              localStorage.setItem("sCount",response.sCount)
            }
        })
}, [
    
])
  const[loginstatus,setLoginstatus] = useState(localStorage.getItem('loginstatus'))
  const[expirationTime,setExpTime] =useState(localStorage.getItem('expirationTime'))
  if(loginstatus && expirationTime && Date.now() > Number(expirationTime)){
    localStorage.removeItem('loginstatus');
    localStorage.removeItem('expirationTime');
  }


  return ( 
    
    <>
    <LoginContext.Provider value={{loginstatus,setLoginstatus,expirationTime,setExpTime,verifyCount,setVerifyCount}}>
    <Router>
    <Routes>
      
    <Route path='/' element={ <Protected Component={Dashboard}/> }></Route>
    
    <Route path='/login' element={ <Protected Component={Login}/> }></Route>

    <Route path='/category' element={ <Protected Component={Category}/> }></Route>
    <Route path='/department' element={ <Protected Component={Department}/> }></Route>
    <Route path='/doctor' element={ <Protected Component={Doctor}/> }></Route>
    <Route path='/banner' element={ <Protected Component={Banner}/> }></Route>
    <Route path='/hospital' element={ <Protected Component={Hospital}/> }></Route>
    <Route path='/support' element={ <Protected Component={Support}/> }></Route>
    <Route path='/withdrawl' element={ <Protected Component={WithdrawlRequest}/> }></Route>
    <Route path='/notification' element={ <Protected Component={Notification}/> }></Route>
    <Route path='/doctor/detailS/:doctorId' element={ <Protected Component={ViewDoc}/> }></Route>
    <Route path='/hospital/detailS/:hospitalId' element={ <Protected Component={ViewHospital}/> }></Route>
    <Route path='/appointment' element={ <Protected Component={Appointment}/> }></Route>
    <Route path='/nursing' element={ <Protected Component={Nursing}/> }></Route>
    <Route path='/verify' element={ <Protected Component={VerificationRequest}/> }></Route>
    <Route path='/nursing-data' element={ <Protected Component={NursingData}/> }></Route>
    <Route path='/patient' element={ <Protected Component={Patient}/> }></Route>
    <Route path='/patient/detail/:patientId' element={ <Protected Component={ViewPatient}/> }></Route>
    <Route path='/charges' element={ <Protected Component={Charges}/> }></Route>
    <Route path='/degree' element={ <Protected Component={Degree}/> }></Route>
    <Route path='/degree' element={ <Protected Component={Pending}/> }></Route>
  
    </Routes>
    </Router>
    </LoginContext.Provider>
    </>
   );
}

export default App;
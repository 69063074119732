import { Link } from "react-router-dom";
import Side from "../partials/Side";
import { useEffect, useState } from "react";
import { url } from "../../url.config";
import { Bars, MutatingDots } from 'react-loader-spinner'
import { toast } from "react-toastify";
import EditCategory from "./EditCategory";
import AddCategory from "./AddCategory";
function Category() {
    const [loaded, setLoaded] = useState(false)
    const token = localStorage.getItem('token')
    const [category,setCategory]=useState([])
    const [categoryId,setCategoryId]=useState("")
    useEffect(() => {
        fetch(`${url}/categories`, {
            headers: { "Content-Type": "application/json" }
        }).then((res) => { return res.json() })
            .then(response => {
                if (response.status === true) {
                    setCategory(response.data)
                    setLoaded(true)
                    console.log(response.data)
                  
                }
            })
    }, [
        
    ])
    function handleDelete(e,categoryId){
        const result= window.confirm("Are You Sure To Delete Category")
        if(result===true){
            fetch(`${url}/category/delete/${categoryId}`, {
                method:"DELETE",
                headers: { Authorization: `bearer ${token}`, "Content-Type": "application/json" }
            }).then((res) => { return res.json() })
                .then(response => {
                    if (response.status === true) {
                        toast.success(response.message)
                        window.location.reload()
                      
                    }
                }) 
        }
    }
    return (
        <>
            <div className="container-fluid" style={{ background: "#EAF8EF", height: "100vh" }}>
                <div className="row">
                    <Side />
                    <div className="col-md-2"> </div>
                    <div className="col-md-10">
                        <h3 className="ms-5 mt-3">CATEGORIES</h3>
                        <button data-bs-toggle="modal" data-bs-target="#staticBackdrop2" style={{border:"none",background:"green",color:"white",paddingLeft:"20px",paddingRight:"20px",paddingTop:"5px",paddingBottom:"5px",borderRadius:"5px"}}>Add + </button>

                        {loaded == false ?
                            <div className="loader" style={{ marginLeft: "46%", marginTop: "21%" }}>
                                <MutatingDots
                                    height="100"
                                    width="100"
                                    color="#02BBB7"
                                    secondaryColor='#02BBB7'
                                    radius='12.5'
                                    ariaLabel="mutating-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                />
                            </div>
                            :
                        <div className='table-banner'>
                            <table className='table table-borderless text-center mt-5 table-banner'>
                                <thead className='mb-2'>
                                    <tr className='' style={{ background: "#F6F6F6", height: "50px" }}>
                                        <th>S No</th>
                                        <th>Title</th>
                                        <th>Image</th>
                                       
                                        <th>Edit</th>
                                        <th>Delete</th>
                                    </tr>
                                </thead>
                                <tbody className=''>
                                    {category.map((i, key) => (
                                        <>
                                            <tr className="mt-2" style={{ background: "#F0EBEB" }}>
                                                <td>{key+1}</td>
                                                <td>{i.name}</td>
                                                <td>
                                                    {/* {showFullText ? (
                                                                <div dangerouslySetInnerHTML={{ __html: i.description }}></div>
                                                            ) : (
                                                                <p >{i.description.slice(0, 30)}...</p>
                                                            )}
                                                            <Link onClick={(id) => { toggleReadMore(i._id) }}>
                                                                {showFullText ? 'Read Less' : 'Read More'}
                                                            </Link> */}
                                                    <img style={{ width: "100px" }} src={i.icon} />
                                                </td>
                                               
                                               
                                                <td><Link data-bs-toggle="modal" data-bs-target="#staticBackdrop3" onClick={(e)=>{
                                                    setCategoryId(i._id)
                                                }}><i className="bi bi-pencil-fill text-success"></i></Link></td>
                                                <td><Link onClick={(e)=>{handleDelete(e,i._id)}}><i class="bi bi-trash3-fill text-danger"></i></Link></td>
                                            </tr>
                                            <br />
                                        </>
                                 ))}

                                </tbody>
                            </table>
                           
                        </div>
}
                    </div>
                </div>
            </div>
            <div class="modal fade" id="staticBackdrop3" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" style={{ border: "none" }}>
                            <div class="modal-content" style={{ borderRadius: "15px", border: "none" }}>
                                <div class="modal-header">
                                    <h5 class="modal-title" id="staticBackdrop">Edit Doctor</h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">

                                    {/* <EditDoctor data={doctorId} /> */}
                                   <EditCategory categoryId={categoryId}/>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="modal fade" id="staticBackdrop2" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" style={{ border: "none" }}>
                            <div class="modal-content" style={{ borderRadius: "15px", border: "none" }}>
                                <div class="modal-header">
                                    <h5 class="modal-title" id="staticBackdrop">Add category</h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">

                                    {/* <EditDoctor data={doctorId} /> */}
                                    <AddCategory/>
                                  
                                  
                                </div>

                            </div>
                        </div>
                    </div>
        </>
    );
}

export default Category;
import { useEffect, useState } from "react"
import { url } from "../../url.config"
import { toast } from "react-toastify"
function EditNursing(props) {
    const [title,setTitle]=useState("")
    const [image,setImage]=useState("")
    const[description,setDescription]=useState("")
    const [status,setStatus]=useState(null)
    const [nursingDataId,setNursingDataId]=useState("")
    const token = localStorage.getItem('token')

    useEffect(()=>{
        setTitle(props.title)
        setImage(props.image)
        setDescription(props.description)
        setStatus(props.status)
        setNursingDataId(props.nursingDataId)
    },[props])

    function handleImage(e){
        const formData = new FormData();

        formData.append('image', e.target.files[0])
        console.log(e.target.file)
        // console.log(fileList)
        fetch(`${url}/upload-single-image`, {
            method: "POST",
            //   headers :{"Content-Type":"application/json" },
            body: formData
        }).then((res) => { return res.json() })
            .then(response => {
                setImage(response.imageUrl) 
                //  console.log(response.imageUrl)
            })
    }
    function  handleSubmit(e){
        e.preventDefault()
        const formData ={title,image,description,status}
        console.log(formData)
        fetch(`${url}/home-nursing/edit/${nursingDataId}`, {
         method:"POST",
         headers: { Authorization: `bearer ${token}`, "Content-Type": "application/json" },
         body:JSON.stringify(formData)
     }).then((res) => { return res.json() })
         .then(response => {
             if (response.status === true) {
                 toast.success(response.message)
                window.location.reload()
             }
         })
     }
    return ( 
        <>
         <form onSubmit={(e)=>{handleSubmit(e)}}>
                <label>Title*</label>
                <input type="text" className="form-input"
                 value={title}
                 onChange={(e) => { setTitle(e.target.value); }}
                />
                <label className="mt-2">Description*</label>
                <input type="text" className="form-input"
                 value={description}
                 onChange={(e) => { setDescription(e.target.value); }}
                />
                
                <label  className="mt-2">Image*</label>
                <input type="file" className="form-input"
                // value={fullName}
                 onChange={handleImage}
                />
               
                    <img src={image} style={{width:"150px", marginTop:"10px"}}/>
              <br/>
                    <label  className="mt-2">Status*</label>
                <br />
                <select className="form-input" value={status}  onChange={(e)=>{setStatus(e.target.value)}}>
                    <option selected>Select</option>
                    <option value={`${1}`} >Active</option>
                    <option value={`${0}`} >Inactive</option>
                </select>

                <button type="submit" className="submit-btn">Submit</button>
            </form>
        </>
     );
}

export default EditNursing;